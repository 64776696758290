import './App.css';
import React from 'react';
import Routers from "./router/Router";
class App extends React.Component {
    componentDidMount(){}
    render() {
        return(
            <Routers/>
        );
    }
}

export default App;
